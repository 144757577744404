import i18next from "i18next";
import * as yup from "yup";

export interface SamplesStepForm {
  collectionDate: string;
  remarks?: string | null;
  cards?: {
    card: number;
    animal?: string | null;
    parity?: string | null;
    problemAnswer?: string | null;
  }[];
}

export const getSamplesStepFormSchema = () => {
  return yup.object({
    collectionDate: yup.string().required().label(i18next.t("sample-request.collection-date")),
    remarks: yup.string().notRequired().label(i18next.t("sample-request.remarks")),
    cards: yup
      .array()
      .of(
        yup.object().shape({
          card: yup.number().min(21).required().label(i18next.t("sample-request.card")),
          animal: yup.string().notRequired().label(i18next.t("sample-request.animal")),
          parity: yup.string().notRequired().label(i18next.t("sample-request.parity")),
          problemAnswer: yup.string().required().label(i18next.t("general.this"))
        })
      )
      .uniqueProperty(i18next.t("sample-request-create.cards-duplicate"), "cards", "card")
      .min(1, i18next.t("sample-request-create.cards-empty"))
  });
};
