import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import {
  AddSampleRequestMutation,
  AddSampleRequestMutationVariables,
  EditSampleRequestMutation,
  EditSampleRequestMutationVariables,
  GetSampleRequestQuery,
  GetSampleRequestQueryVariables,
  GetSampleResultCalculationsForCustomerQuery,
  GetSampleResultCalculationsForCustomerQueryVariables,
  GetSampleResultCalculationsForLocationQuery,
  GetSampleResultCalculationsForLocationQueryVariables,
  GetSampleResultTableForCustomerQuery,
  GetSampleResultTableForCustomerQueryVariables,
  GetSampleResultTableForLocationQuery,
  GetSampleResultTableForLocationQueryVariables,
  SampleRequest,
  SampleRequestEditInput,
  SampleRequestInput,
  SampleResultCalculations,
  SampleResultTable,
  SampleResultTableInput
} from "../models/API";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { SpeciesGroup } from "../models/API";
import * as mutations from "../graphql/mutations";
import { BaseQuery } from "../graphql/interfaces";
import { Dispatch, SetStateAction } from "react";
import { IToast } from "../models/toast.model";
import { toFixedNumber } from "../helpers/number.helper";
import { FarmStepForm } from "../models/forms/farm-step-form.model";
import { AnimalStepForm } from "../models/forms/animal-step-form.model";
import { DietStepForm } from "../models/forms/diet-step-form.model";
import { SamplesStepForm } from "../models/forms/samples-step-form.mode";
import { YesNoUnknown } from "../models/enums/yesNo";
import dayjs from "dayjs";
import { ProductionSystemSwine } from "../models/enums/productionSystem.enum";
import { EditSampleRequestForm } from "../models/forms/edit-sample-request.model";

export const getSampleResult = async (
  sampleRequestId: string,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<SampleRequest | null> => {
  try {
    const request: BaseQuery<GetSampleRequestQueryVariables> = {
      query: queries.getSampleRequest,
      variables: { sampleRequestId }
    };
    const result = await API.graphql<GraphQLQuery<GetSampleRequestQuery>>(request);

    const sampleRequest = result.data?.getSampleRequest?.sampleRequest;

    if (sampleRequest) {
      processSampleRequest(sampleRequest);

      return sampleRequest;
    } else {
      return null;
    }
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading sample result", error: errors[0].message });
    return null;
  }
};

export const getSampleResultsForLocation = async (
  locationId: string,
  input: SampleResultTableInput,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<SampleResultTable | null> => {
  try {
    const request: BaseQuery<GetSampleResultTableForLocationQueryVariables> = {
      query: queries.getSampleResultTableForLocation,
      variables: { locationId, input }
    };
    const result = await API.graphql<GraphQLQuery<GetSampleResultTableForLocationQuery>>(request);

    const sampleResultTable = result.data?.getSampleResultTableForLocation;
    if (sampleResultTable) {
      // Process backend data
      sampleResultTable.rows?.forEach((row) => {
        // Round average to 1 decimal
        row.average = row.average ? toFixedNumber(row.average, 1) : row.average;
      });
      return sampleResultTable;
    } else {
      return null;
    }
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading sample results for location", error: errors[0].message });
    return null;
  }
};

export const getSampleResultsForCustomer = async (
  customerId: string | null,
  input: SampleResultTableInput,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<SampleResultTable | null> => {
  try {
    const request: BaseQuery<GetSampleResultTableForCustomerQueryVariables> = {
      query: queries.getSampleResultTableForCustomer,
      variables: { customerId, input }
    };
    const result = await API.graphql<GraphQLQuery<GetSampleResultTableForCustomerQuery>>(request);

    const sampleResultTable = result.data?.getSampleResultTableForCustomer;
    if (sampleResultTable) {
      // Process backend data
      sampleResultTable.rows?.forEach((row) => {
        // Round average to 1 decimal
        row.average = row.average ? toFixedNumber(row.average, 1) : row.average;
      });
      return sampleResultTable;
    } else {
      return null;
    }
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading sample results for customer", error: errors[0].message });
    return null;
  }
};

export const getSampleResultCalculationsForLocation = async (
  locationId: string,
  phaseId: string | null,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<SampleResultCalculations | null> => {
  try {
    const request: BaseQuery<GetSampleResultCalculationsForLocationQueryVariables> = {
      query: queries.getSampleResultCalculationsForLocation,
      variables: {
        locationId,
        phaseId
      }
    };
    const result = await API.graphql<GraphQLQuery<GetSampleResultCalculationsForLocationQuery>>(request);

    const sampleResultCalculations = result.data?.getSampleResultCalculationsForLocation;
    if (sampleResultCalculations) {
      // Process backend data
      // Round total average to 1 decimal
      sampleResultCalculations.totalAverage = sampleResultCalculations.totalAverage
        ? toFixedNumber(sampleResultCalculations.totalAverage, 1)
        : sampleResultCalculations.totalAverage;
      // Round performance categories to 1 decimal
      sampleResultCalculations.deficient = sampleResultCalculations.deficient
        ? toFixedNumber(sampleResultCalculations.deficient, 1)
        : sampleResultCalculations.deficient;
      sampleResultCalculations.insufficient = sampleResultCalculations.insufficient
        ? toFixedNumber(sampleResultCalculations.insufficient, 1)
        : sampleResultCalculations.insufficient;
      sampleResultCalculations.adequate = sampleResultCalculations.adequate
        ? toFixedNumber(sampleResultCalculations.adequate, 1)
        : sampleResultCalculations.adequate;
      sampleResultCalculations.optimum = sampleResultCalculations.optimum
        ? toFixedNumber(sampleResultCalculations.optimum, 1)
        : sampleResultCalculations.optimum;
      return sampleResultCalculations;
    } else {
      return null;
    }
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading sample result calculations for location", error: errors[0].message });
    return null;
  }
};

export const getSampleResultCalculationsForCustomer = async (
  customerId: string,
  phaseId: string | null,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<SampleResultCalculations | null> => {
  try {
    const request: BaseQuery<GetSampleResultCalculationsForCustomerQueryVariables> = {
      query: queries.getSampleResultCalculationsForCustomer,
      variables: {
        customerId,
        phaseId
      }
    };
    const result = await API.graphql<GraphQLQuery<GetSampleResultCalculationsForCustomerQuery>>(request);
    const sampleResultCalculations = result.data?.getSampleResultCalculationsForCustomer;
    if (sampleResultCalculations) {
      // Process backend data
      // Round total average to 1 decimal
      sampleResultCalculations.totalAverage = sampleResultCalculations.totalAverage
        ? toFixedNumber(sampleResultCalculations.totalAverage, 1)
        : sampleResultCalculations.totalAverage;
      // Round performance categories to 1 decimal
      sampleResultCalculations.deficient = sampleResultCalculations.deficient
        ? toFixedNumber(sampleResultCalculations.deficient, 1)
        : sampleResultCalculations.deficient;
      sampleResultCalculations.insufficient = sampleResultCalculations.insufficient
        ? toFixedNumber(sampleResultCalculations.insufficient, 1)
        : sampleResultCalculations.insufficient;
      sampleResultCalculations.adequate = sampleResultCalculations.adequate
        ? toFixedNumber(sampleResultCalculations.adequate, 1)
        : sampleResultCalculations.adequate;
      sampleResultCalculations.optimum = sampleResultCalculations.optimum
        ? toFixedNumber(sampleResultCalculations.optimum, 1)
        : sampleResultCalculations.optimum;
      return sampleResultCalculations;
    } else {
      return null;
    }
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading sample result calculations for customer", error: errors[0].message });
    return null;
  }
};

export const createSampleRequest = async (
  farmStep: FarmStepForm,
  animalStep: AnimalStepForm,
  dietStep: DietStepForm,
  samplesStep: SamplesStepForm,
  testReasonProblem: string | null,
  isReproduction: boolean
): Promise<string | null> => {
  try {
    const sampleRequest: SampleRequestInput = {
      locationId: farmStep.farmId,
      barn: farmStep.barn,
      housingSystem: farmStep.speciesGroup === SpeciesGroup.Swine ? farmStep.housingSwine || "" : farmStep.housingSwine || "",
      productionSystem:
        farmStep.speciesGroup === SpeciesGroup.Swine
          ? farmStep.productionSwine === ProductionSystemSwine.Other
            ? farmStep.productionSwineOther || null
            : farmStep.productionSwine || null
          : null,
      phaseId: animalStep.phaseId || "",
      specificPhase: animalStep.specificPhase || "",
      problemArea: animalStep.problemArea || "",
      specificProblemArea: animalStep.specificProblemArea || "",
      testReasonProblem: testReasonProblem || "",
      geneticSupplier:
        animalStep.speciesGroup === SpeciesGroup.Swine
          ? animalStep.geneticSupplier === "Other"
            ? animalStep.geneticSupplierOther
            : animalStep.geneticSupplier
          : animalStep.genetics,
      geneticLineage:
        animalStep.speciesGroup === SpeciesGroup.Swine
          ? animalStep.geneticLineage === "Other"
            ? animalStep.geneticLineageOther
            : animalStep.geneticLineage
          : animalStep.genetics,
      testReason: "", //TODO: Remove
      sex: animalStep.sex,
      caLevel: dietStep.caLevel,
      d3Level: dietStep.d3Level,
      pLevel: dietStep.pTotal,
      ohLevel: dietStep.ohLevel,
      phytase: dietStep.phytase,
      phytaseUnit: dietStep.phytaseUnit || "",
      collectionDate: dayjs(samplesStep.collectionDate).format("YYYY-MM-DD"),
      remarks: samplesStep.remarks,
      cards:
        samplesStep.cards?.map((_) => {
          return {
            cardId: _.card.toString(),
            animalId: _.animal,
            parity: isReproduction ? _.parity : null,
            problemAnswer: getProblemAnswer(_.problemAnswer)
          };
        }) || []
    };

    const request: BaseQuery<AddSampleRequestMutationVariables> = {
      query: mutations.addSampleRequest,
      variables: { input: sampleRequest }
    };
    const result = await API.graphql<GraphQLQuery<AddSampleRequestMutation>>(request);

    return result.data?.addSampleRequest?.sampleRequest?.id || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    throw new Error(errors[0].message);
  }
};

export const editSampleRequest = async (id: string, values: EditSampleRequestForm): Promise<SampleRequest | null> => {
  const editSampleRequest: SampleRequestEditInput = {
    sampleRequestId: id,
    caLevel: values.caLevel,
    d3Level: values.d3Level,
    ohLevel: values.ohLevel,
    pLevel: values.pTotal,
    phytase: values.phytase,
    phytaseUnit: values.phytaseUnit,
    geneticSupplier: values.geneticSupplier === "Other" ? values.geneticSupplierOther : values.geneticSupplier,
    geneticLineage: values.geneticLineage === "Other" ? values.geneticLineageOther : values.geneticLineage
  };

  try {
    const request: BaseQuery<EditSampleRequestMutationVariables> = {
      query: mutations.editSampleRequest,
      variables: { input: editSampleRequest }
    };
    const result = await API.graphql<GraphQLQuery<EditSampleRequestMutation>>(request);

    if (result.data?.editSampleRequest?.sampleRequest) {
      processSampleRequest(result.data?.editSampleRequest?.sampleRequest);
      return result.data?.editSampleRequest?.sampleRequest;
    }
    return null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    throw new Error(errors[0].message);
  }
};

const getProblemAnswer = (problemAnswer: string | null | undefined): boolean | null => {
  if (problemAnswer === YesNoUnknown.Yes) {
    return true;
  } else if (problemAnswer === YesNoUnknown.No) {
    return false;
  } else {
    return null;
  }
};

const processSampleRequest = (sampleRequest: SampleRequest): void => {
  sampleRequest?.samples?.forEach((sample) => {
    // Remove all results that are not 25-OHD3
    sample.results = sample.results?.filter((result) => result.analysis === "25-OHD3" || result.analysisUnit === "25-OHD3 (µg/L)");
    // Round all results to 1 decimal
    sample.results?.forEach((result) => {
      result.result = result.result ? toFixedNumber(result.result, 1) : result.result;
    });
  });
  // Round average to 1 decimal
  sampleRequest.average = sampleRequest.average ? toFixedNumber(sampleRequest.average, 1) : sampleRequest.average;
};
