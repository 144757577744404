import { useContext, useEffect, useState } from "react";
import { useLayout } from "../../../contexts/layout.context";
import { useTranslation } from "react-i18next";
import { routes, routeTypes } from "../../../routes";
import { DsmTableCustomEvent, DsmTableData } from "@dsm-dcs/design-system";
import { getSampleResultsForCustomer } from "../../../services/sample.service";
import { AuthContext } from "../../../contexts/auth.context";
import { Role } from "../../../models/enums/role.enum";
import { Page, SortDirection } from "../../../models/API";
import dayjs from "dayjs";
import { DsmEmptyState, DsmGrid, DsmLoadingIndicator, DsmPagination, DsmTable } from "@dsm-dcs/design-system-react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/pageHeader/PageHeader";

function SampleRequestList() {
  //Hooks
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customer, role } = useContext(AuthContext);

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [samplesTable, setSamplesTable] = useState<DsmTableData | null>(null);
  const [samplesTablePages, setSamplesTablePages] = useState<Page[]>([]);
  const [currentSamplesTablePage, setCurrentSamplesTablePage] = useState<number>(1);

  useEffect(() => {
    setPageTitle(t("sample-request-list.page.title"));
    setCrumbs([{ title: t("sample-request-list.page.title"), type: routeTypes.samples }]);
    initData();
  }, []);

  //Methods
  const initData = async () => {
    await loadSamplesTable();
    setIsLoading(false);
  };

  const loadSamplesTable = async (page?: number) => {
    let pageToken: string | null = null;
    if (page) {
      pageToken = samplesTablePages.find((_) => _.page === page)?.token ?? null;
    }

    const sampleResults = await getSampleResultsForCustomer(
      role === Role.Customer ? customer?.id || "" : null,
      { itemsPerPage: 10, sortDirection: SortDirection.Desc, pageToken, excludePendingResults: false },
      setToast
    );

    const samplesTableData: DsmTableData = {
      columns: [
        { id: "collectionDate", label: t("sample-request.collection-date") },
        { id: "name", label: t("sample-request.name") },
        { id: "farm", label: t("sample-request.farm") },
        { id: "phase", label: t("sample-request.phase") },
        { id: "average", label: t("sample-request.average") }
      ],
      data:
        sampleResults?.rows?.map((sampleResult) => {
          return {
            isClickable: true,
            id: sampleResult.sampleRequestId || "",
            cells: [
              { value: dayjs(sampleResult.collectionDate || "").format(t("general.date-format")) },
              { value: sampleResult.name || "" },
              { value: sampleResult.locationName || "" },
              { value: sampleResult.phaseName || "" },
              {
                badges: [
                  {
                    value: sampleResult.average
                      ? `${sampleResult.average?.toString() || ""} ${t("general.d3-unit")}`
                      : t("general.result-status.pending"),
                    variant: sampleResult.average ? "success" : "primary"
                  }
                ]
              }
            ]
          };
        }) || []
    };
    if (role === Role.Manager || role === Role.Admin) {
      samplesTableData.columns.splice(2, 0, { id: "customer", label: t("sample-request.customer") });
      sampleResults?.rows?.forEach((sampleResult, index) => {
        samplesTableData.data[index].cells.splice(2, 0, { value: sampleResult.customer?.name || "" });
      });
    }
    setSamplesTable(samplesTableData);
    setSamplesTablePages(sampleResults?.pages || []);
  };

  const handleResultListPageChange = async (page: number) => {
    setCurrentSamplesTablePage(page);
    loadSamplesTable(page);
  };

  const handleTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSample.replace(":sampleID", e.detail));
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader header={t("sample-request-list.page.title")} description={t("sample-request-list.page.description")}></PageHeader>
      {!isLoading ? (
        <div className="card card--table">
          {samplesTable?.data.length ? (
            <>
              <DsmTable data={samplesTable} onDsmRowClick={handleTableRowClick}></DsmTable>
              {samplesTablePages.length > 0 ? (
                <DsmPagination
                  currentPage={currentSamplesTablePage}
                  pageCount={samplesTablePages.length + 1}
                  onDsmChangePage={(e) => handleResultListPageChange(e.detail)}
                ></DsmPagination>
              ) : null}
            </>
          ) : (
            <DsmEmptyState icon="charts/pie-chart-01" header={t("sample-request-list.samples-table.empty")}></DsmEmptyState>
          )}
        </div>
      ) : (
        <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
      )}
    </DsmGrid>
  );
}

export default SampleRequestList;
