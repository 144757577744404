import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../contexts/layout.context";
import { routeTypes, routes } from "../../routes";
import { DsmButton, DsmEmptyState, DsmGrid, DsmLoadingIndicator, DsmTable } from "@dsm-dcs/design-system-react";
import styles from "./Customers.module.scss";
import PageHeader from "../../components/pageHeader/PageHeader";
import { getCustomersForUser } from "../../services/user.service";
import { DsmTableCustomEvent, DsmTableData, RowActionClickEvent } from "@dsm-dcs/design-system";
import { BaseCustomer } from "../../models/API";
import { AuthContext } from "../../contexts/auth.context";
import { CustomersTableActions } from "../../models/enums/actions.enum";
import { useNavigate } from "react-router-dom";
import { getLocationsForCustomer } from "../../services/location.service";
import { Role } from "../../models/enums/role.enum";

function Customers() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer, handleCustomer, role } = useContext(AuthContext);

  //State
  const [isLoadingCustomers, setIsLoadingCustomers] = useState<boolean>(true);
  const [isLoadingFarms, setIsLoadingFarms] = useState<boolean>(false);
  const [customers, setCustomers] = useState<BaseCustomer[]>([]);
  const [customersTable, setCustomersTable] = useState<DsmTableData>();
  const [farmsTable, setFarmsTable] = useState<DsmTableData>();

  //Effects
  useEffect(() => {
    handleCustomer(null);
    setDefaultPageData();
    getInitData();
  }, []);

  useEffect(() => {
    handleFarms();
    if (customer) {
      setPageTitle(customer.name ?? "...");
      setCrumbs([
        { title: t("customers.page.title"), url: routes.customers, type: routeTypes.customers },
        { title: customer.name ?? "", type: routeTypes.customerSelected }
      ]);
    } else {
      setDefaultPageData();
    }
  }, [customer]);

  //Methods
  const setDefaultPageData = () => {
    setPageTitle(t("customers.page.title"));
    setCrumbs([{ title: t("customers.page.title"), type: routeTypes.customers }]);
  };

  const getInitData = async () => {
    const customers = await getCustomersForUser(setToast);
    setCustomers(customers);

    const tableData = {
      columns: [
        { id: "name", label: t("customer.name") },
        { id: "farms", label: t("customer.farms") },
        { id: "kits", label: t("customer.kits") },
        { id: "requests", label: t("customer.requests") },
        { id: "budget", label: t("customer.budget") }
      ],
      data: customers
        .sort((a, b) => {
          return (a.name || "").localeCompare(b.name || "");
        })
        .map((_) => {
          return {
            isClickable: true,
            actions: [
              {
                type: CustomersTableActions.CreateSampleRequest,
                icon: "development/qr-code-02",
                title: t("sample-request-create.page.title")
              },
              {
                type: CustomersTableActions.CreateFarm,
                icon: "maps-travel/map-02",
                title: t("farm-create.page.title")
              }
            ],
            id: _.id || "",
            cells: [
              { headerText: _.name || "" },
              { value: _.numberOfFarms?.toString() || "" },
              { value: _.numberOfKits?.toString() || "" },
              { value: _.numberOfRequests?.toString() || "" },
              { value: getBudget(_) }
            ]
          };
        })
    };

    if (role === Role.Admin) {
      tableData.data.forEach((row) => {
        row.actions.splice(0, 0, {
          type: CustomersTableActions.CreateKitRequest,
          icon: "finance-ecommerce/shopping-cart-03",
          title: t("order-new-kit.page.title")
        });
      });
    }

    setCustomersTable(tableData);
    setIsLoadingCustomers(false);
  };

  const getBudget = (customer: BaseCustomer) => {
    const numberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: customer.currency ?? "EUR" });
    return numberFormat.format(customer.currentBudget ?? 0);
  };

  const handleFarms = async () => {
    if (!customer) {
      setFarmsTable(undefined);
      return;
    }

    setIsLoadingFarms(true);
    const locations = await getLocationsForCustomer(customer?.id || "", setToast);
    setFarmsTable({
      columns: [
        { id: "name", label: t("farm.name") },
        { id: "species", label: t("farm.species") },
        { id: "location", label: t("general.location") }
      ],
      data: locations.map((_) => {
        return {
          isClickable: true,
          id: _.id || "",
          cells: [
            { headerText: _.name || "" },
            { value: _.groups && _.groups.length > 0 ? t(`general.species.${_.groups[0].toLowerCase()}`) : "" },
            { value: `${_.address?.city}, ${_.address?.country?.name}` }
          ]
        };
      })
    });
    setIsLoadingFarms(false);
  };

  const handleCustomersTableRowClick = (e: DsmTableCustomEvent<string>) => {
    handleCustomer(customers.find((_) => _.id === e.detail) ?? null);
  };

  const handleFarmsTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSamples.replace(":locationId", e.detail));
  };

  const handleCustomersTableAction = (e: DsmTableCustomEvent<RowActionClickEvent>) => {
    if (e.detail.action === CustomersTableActions.CreateKitRequest) {
      navigate(routes.order);
    } else if (e.detail.action === CustomersTableActions.CreateSampleRequest) {
      navigate(routes.sampleRequestCreate);
    } else if (e.detail.action === CustomersTableActions.CreateFarm) {
      navigate(routes.farmCreate);
    }
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader
        header={customer?.name ? customer.name : t("customers.page.title")}
        actionsSide={
          customer ? (
            <>
              <DsmButton variant="secondary" onClick={() => navigate(routes.farmCreate)}>
                {t("farm-create.page.title")}
              </DsmButton>
              {role === Role.Admin ? (
                <DsmButton variant="secondary" onClick={() => navigate(routes.order)}>
                  {t("order-new-kit.page.title")}
                </DsmButton>
              ) : (
                ""
              )}
              <DsmButton variant="secondary" onClick={() => navigate(routes.sampleRequestCreate)}>
                {t("sample-request-create.page.title")}
              </DsmButton>
            </>
          ) : null
        }
      ></PageHeader>
      {!customer ? (
        <div className={styles.table}>
          {!isLoadingCustomers ? (
            <>
              {customersTable && customersTable.data.length > 0 ? (
                <DsmTable
                  data={customersTable}
                  onDsmRowClick={handleCustomersTableRowClick}
                  onDsmRowActionClick={handleCustomersTableAction}
                ></DsmTable>
              ) : (
                <DsmEmptyState header={t("customers.empty.header")} icon="users/user-01"></DsmEmptyState>
              )}
            </>
          ) : (
            <DsmLoadingIndicator size="md"></DsmLoadingIndicator>
          )}
        </div>
      ) : null}
      {customer ? (
        <div className={styles.table}>
          {!isLoadingFarms ? (
            <>
              {farmsTable && farmsTable.data.length > 0 ? (
                <DsmTable data={farmsTable} onDsmRowClick={handleFarmsTableRowClick}></DsmTable>
              ) : (
                <DsmEmptyState header={t("customers.empty.header")} icon="maps-travel/map-02"></DsmEmptyState>
              )}
            </>
          ) : (
            <DsmLoadingIndicator size="md"></DsmLoadingIndicator>
          )}
        </div>
      ) : null}
    </DsmGrid>
  );
}

export default Customers;
